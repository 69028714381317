<div class="challenge-wrapper">

  <div class="challenge-header">
    <div class="left"></div>
    <div class="center">
      <h1 style="font-size: 3.5em">Welcome to Your Wedding Challenge</h1>
      <hr>
      <p style="font-size: 3em">Great to see you here! You have to complete all of the following challenges to get to
        your final wedding gift. Have
        fun and enjoy life!</p>
    </div>
  </div>
  <div class="right"></div>

  <div class="challenge-stepper">
    <div class="left"></div>
    <div class="center">

      <mat-vertical-stepper linear="true" selectedIndex="3" #stepper>
        <ng-template matStepperIcon="done">
          <mat-icon>done</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="edit">
          <mat-icon>done</mat-icon>
        </ng-template>

        <mat-step [completed]="true">
          <ng-template matStepLabel>Cook Some Good ole' German Food</ng-template>
          <p>To solve this challenge you have to cook some German food (maybe some Jager Schnitzel with fries) together. The challenge is solved if you present us a picture of your meal. Have fun and enjoy cooking!</p>
          <div>
            <img src="assets/images/challenge-1-solved.jpeg" alt="challenge-1-solved">
          </div>
        </mat-step>

        <mat-step [completed]="true">
          <ng-template matStepLabel>Floating in Memories</ng-template>
        <p>Go back to Amsterdam on the 31st of December 2018. Find out the name and coordinates of the bar where we played cards together. Solved when you provide the coordinates and name. If you need a hint contact us.</p>
          <div>
            <img src="assets/images/challenge-2-solved.png" alt="challenge-2-solved">
          </div>
        </mat-step>

        <mat-step [completed]="true">
          <ng-template matStepLabel>Take a Break from Life</ng-template>
          <p>Take a long walk at the beach (at least four miles). It is solved when you send a picture of you two walking at the beach (track the walk if possible). Equip yourself with some wine and crackers.</p>
          <div>
            <img style="border-radius: 20px" src="assets/images/challenge-3-solved.jpg" alt="challenge-3-solved">
          </div>
        </mat-step>

        <mat-step [completed]="false">
          <ng-template matStepLabel>This One Is For Levi</ng-template>
          <p>Jug a beer (possibly alcohol free) together. Solved when you provide a video or picture of you two with proof 🍺</p>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>Writing a Letter</ng-template>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>Persisting Memories</ng-template>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>Floating in Memories... Again</ng-template>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>It's Wine O'Clock</ng-template>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>Back to the future</ng-template>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>Cocktail Time</ng-template>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>Travel Planning</ng-template>
        </mat-step>

      </mat-vertical-stepper>

    </div>
    <div class="right"></div>
  </div>
</div>

<div style="text-align: center; padding: 3em" class="final-wedding-gift">
  <h1 style="font-size: 3em">Come and Get Your Wedding Gift ...</h1>
</div>

